import React from 'react'

export default function PressLink({name, link, project}) {
    return (
        <div className="press-link">
            <div className="link-title"><a href={link}>{name}</a></div>
            <div className="link-description">{project}</div>
        </div>
    )
}
