import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'
import PressLink from '../components/PressLink'

const theme = 'bright' //Set the color theme here

export default function About() {
    return (
        <Layout theme={ theme } currentPath = 'about'>

            <Helmet>
                <title>{"VOLNA / About"}</title>
                <meta name="description" content="About VOLNA" />
                <link rel="canonical" href="https://volna-media.com/about" />
                <meta property="og:url" content="https://volna-media.com/about" />
                <meta property="og:title" content="VOLNA / About" />
                <meta property="og:description" content="About VOLNA" />
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:creator" content="VOLNA" />
                <meta name="twitter:title" content="VOLNA / About" />
                <meta name="twitter:description" content="About VOLNA" />
            </Helmet>

            <div className='static-page'>
                <div className="about-intro-text">
                    <h2>
                    VOLNA is an independent art collective that creates cutting-edge designs and spatial art. We use a wide and ever-expanding set of media and technologies to create everything from subtle light and kinetic objects to large-scale installations. But we don't just fill spaces—we create them too. Our work includes scenographies that blend physical and digital environments, as well as temporary architecture projects utilizing a variety of materials and technologies. The one steady factor is that we strive to create artworks—done on commission or on our own initiative—that are always visually and technologically innovative.
                    </h2>
                </div>
                
                <div className='about-section'>
                    <div className='about-section-heading'>
                        <h3>Studio</h3>
                    </div>
                    <div className="about-section-content">
                        <p className="body-large">Formed in 2016 as a loose cooperative of architects, artists, engineers, cultural workers and artisans, VOLNA is based in Karlsruhe, Germany. We have an extensive track record of successful collaborations with major museums, theaters, festivals, biennials, educational institutions, grassroots cultural initiatives, nightclubs, and corporate clients. Whether it's mechanical flowers under your feet or intricate environmentally sustainable art objects in the Alps, a dramaturgically designed immersive light forest for a choreographic performance or interactive light mirrors for a university bus stop, light set-ups for underground music venues or the entire architecture for a festival with 15,000 visitors, or even a virtual Kunsthalle with lifelike lighting effects, in all of our projects VOLNA creates unique and exciting artistic environments that integrate organically into each site with own unique context and specifics.</p>

                        <p className="body-large">Does your space feel empty? Do you have a grand idea? An exhibition, club party or maybe an immersive performance you’ve been itching to put together? Or perhaps some entirely new format that doesn't even have a name or genre yet? We’ll help fill the void and illuminate your dream of a meaningful space! <a href="/contact">Toss your idea at us</a> and we'll be happy to help develop it artistically, engineer it out and work with you to navigate from a raw idea to realization with our expertise in project management and art direction.</p>
                    </div>                    
                </div>

                <div className='about-section'>
                    <div className='about-section-heading'>
                        <h3>Press</h3>
                    </div>
                    <div className="about-section-content">
                            <div className="press">
                            <PressLink name="Lightecture" link="https://www.lightecture.com/arte_digital/fluchtpunkte-volna-arte-digital/" project="Fluchtpunkte"/>
                            <PressLink name="Architizer" link="https://architizer.com/blog/projects/reeds/" project="Reeds"/>
                            <PressLink name="Journal Du Design" link="https://www.journal-du-design.fr/art/reeds-installation-inspiree-des-roseaux-au-bord-de-leau-144085/" project="Reeds"/>
                            <PressLink name="DesignNuance" link="https://www.designnuance.com/russia-based-studio-volna-designs-reeds-a-kinetic-light-installation/" project="Reeds"/>
                            <PressLink name="Visual Atelier 8" link="https://www.visualatelier8.com/art/2021/12/volna" project="Reeds"/>
                            <PressLink name="Scenography Today" link="https://www.scenographytoday.com/spatials/volna-nympheas/" project="Nymphéas"/>
                            <PressLink name="Non Architecture" link="https://www.nonarchitecture.eu/2021/01/04/nympheas-a-new-art-within-architecture-project-by-volna/" project="Nymphéas"/>
                            <PressLink name="FRAME Magazine" link="https://frameweb.com/article/shows/heres-how-4-exhibitions-made-the-quick-shift-to-the-digital-sphere-amid-covid-19-induced-restrictions" project="Keep Yourself Clean Exhibition"/>
                            <PressLink name="ARTConnect Magazine" link="https://web.archive.org/web/20210507042406/https://www.artconnect.com/magazine/volna-keep-yourself-clean/" project="Keep Yourself Clean Exhibition"/>
                            <PressLink name="Digicult" link="http://digicult.it/art/keep-yourself-clean-the-first-solo-exhibition-by-volna/" project="Keep Yourself Clean Exhibition"/>
                            <PressLink name="CLOT Mag" link="https://www.clotmag.com/news/online-exhibition-keep-yourself-clean-by-volna-art-collective" project="Keep Yourself Clean Exhibition"/>
                            <PressLink name="Colta" link="https://www.colta.ru/articles/art/24700-pavel-golubev-volna-pervaya-virtualnaya-retrospektiva" project="Keep Yourself Clean Exhibition"/>
                            <PressLink name="Probeta Magazine" link="http://www.probetamagazine.com/en/english-lighting-art-that-play-with-your-senses" project="Keep Yourself Clean Exhibition"/>
                            <PressLink name="Drain Mag" link="http://drainmag.com/wave-rolling-in/" project="Keep Yourself Clean Exhibition"/>
                            <PressLink name="Scenography Today" link="https://www.scenographytoday.com/volna-duel/" project="Duel"/>
                            <PressLink name="CLOT Mag" link="https://www.clotmag.com/news/insight-duel-kinetic-installation-by-volna-collective" project="Duel"/>
                            <PressLink name="The Calvert Journal" link="https://www.calvertjournal.com/articles/show/11341/russian-kinetic-light-art-duel-volna" project="Duel"/>
                            <PressLink name="Visual Atelier 8" link="https://www.visualatelier8.com/art/2019/8/30/volna-duel" project="Duel"/>
                            <PressLink name="Designcollector" link="https://designcollector.net/likes/duel-by-volna" project="Duel"/>
                            <PressLink name="FAD Magazine" link="https://fadmagazine.com/2019/08/27/volna-a-media-artists-collective-from-st-petersburg-have-created-duel/" project="Duel"/>
                            <PressLink name="VJSpain" link="https://vjspain.com/blog/2019/08/24/la-nueva-instalacion-de-luz-cinetica-del-colectivo-volna-se-llama-duel/?fbclid=IwAR0yNIpFjfYn12NYArYPL2V10s4A8ywNgHz7rdop8kLSqpsffK-UDiTdCfg" project="Duel"/>
                            <PressLink name="Resident Advisor" link="https://www.residentadvisor.net/reviews/24085" project="Present Perfect Festival 2019"/>
                            <PressLink name="Juliet Magazine" link="https://www.juliet-artmagazine.com/smach-2019-una-costellazione-di-arte-cultura-e-storia-nelle-dolomiti" project="Vague"/>
                            <PressLink name="Franz Magazine" link="https://franzmagazine.com/2019/06/20/smach-2019-das-wort-oder-der-ort-oder-das-gefuehl-oder-die-erinnerung-heimat/" project="Vague"/>
                            <PressLink name="Travel On Art" link="https://www.travelonart.com/arte-contemporanea/smach-2019-progetto-land-art-sulle-domiti/?fbclid=IwAR2NDSldJepgeu7ZWigfSw3mkyZ4mu-FSzpLSk51AS7pAzU45Q1ZXOwB-7E" project="Vague"/>
                            <PressLink name="Architizer" link="https://architizer.com/projects/smach2019/" project="Vague"/>
                            <PressLink name="Future Crafter" link="http://www.futurecrafter.com/en/design/the-open-plain" project="The Open Plain"/>
                            <PressLink name="VJSpain" link="http://vjspain.com/blog/2018/07/22/instalacion-diseno-de-iluminacion-by-volna/" project="Coulisse and Aelita"/>
                            <PressLink name="Mixmag interview" link="https://web.archive.org/web/20201129075141/https://mixmag.io/article/146453" project=""/>
                            <PressLink name="VJSpain" link="http://vjspain.com/blog/2018/01/15/volna-schwarzwald/" project="Schwarzwald"/>
                            <PressLink name="Creative Applications" link="http://www.creativeapplications.net/vvvv/powerline-audio-reactive-short-circuit-installation-by-volna/" project="Powerline"/>
                            <PressLink name="Arduino.org" link="https://web.archive.org/web/20170711164505/http://www.arduino.org/makers/powerline" project="Powerline"/>
                            <PressLink name="Vice" link="https://www.vice.com/en_us/article/53wqb5/fall-into-this-elevator-shaft-audiovisual-installation" project="NEUBAU"/>
                            <PressLink name="Future Crafter" link="http://www.futurecrafter.com/en/design/neubau" project="NEUBAU"/>
                        </div> 
                    </div>                    
                </div>
                
            </div>
        </Layout>
    )
}
